<template>
  <div class="body-box">
    <div style="justify-content:space-between;" class="flex">
      <div :class="isExpand ? 'expand course-box' : 'course-box'">
        <div v-for="(item, index) in courseList" :key="index" :class="selectIndex === index
          ? 'course-pane ellipsis select'
          : 'course-pane ellipsis'
          " @click="chooseCourse(item.id, index)">
          {{ item.kcName || "错误" }}
        </div>
      </div>
      <div v-if="isExpand" class="expand-btn" @click="expand">
        全部<i class="el-icon-arrow-down" />
      </div>
      <div v-else class="expand-btn" @click="expand">
        收起<i class="el-icon-arrow-up" />
      </div>
    </div>
    <div v-for="(item, index) in wrongQuestionList" :key="index" style="margin-top: 10px">
      <div class="flex between center wrong-item">
        <div class="wrong-left">
          <div class="flex center">
            <img class="wrong-icon" :src="questionTypeIcon[item.quesType]" /><span class="wrong-type">{{ item.typeName
            }}</span>
          </div>
          <div class="wrong-sub">
            已保存错题：<span>{{ item.num }}</span>道
          </div>
        </div>
        <div>
          <el-button type="success" round @click="rework(item.data)">重新做题</el-button>
          <el-button round @click="analysisClick(item.data)">查看解析</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  getWrongQuestionCourseList,
  getWrongQuestionList,
  getSubjectIdList,
} from "@/api/course/course";
import { questionTypeIcon } from "@/api/emun";
export default {
  data() {
    return {
      courseList: [], // 课程列表
      wrongQuestionList: [], // 错题列表
      isExpand: true, // 是否展开
      selectId: 0, // 选中课程
      selectIndex: 0, // 选中课程下标
      questionTypeIcon: questionTypeIcon, // icon枚举
    };
  },
  // async created() {
  //   await this.getwrongQuestionList()
  // },
  mounted() {
    this.getCourseList();
  },
  methods: {
    // 获取课程列表
    async getCourseList() {
      const res = await getWrongQuestionCourseList();
      if (res.code === 0) {
        this.courseList = res.data;
        console.log(this.courseList);
        this.chooseCourse(this.courseList[0].id, 0);
      }
    },
    // 展开头部课程列表
    expand() {
      console.log("触发事件");
      this.isExpand = !this.isExpand;
    },
    // 选择课程
    async chooseCourse(id, index) {
      this.selectId = id;
      this.selectIndex = index;
      const res = await getWrongQuestionList({ courseId: id });
      if (res.code === 0) {
        this.wrongQuestionList = res.data;
      }
    },
    // 重新做题
    async rework(param) {
      console.log(param, 'param');
      this.$router.push({
        path: "/quesTion/examDetails",
        query: {
          title: "错题记录",
          IsPurchase: true,
          examType: 168,
          wrongTopic: true,
          param: JSON.stringify(param),
        },
        //  `/quesTion/examDetails?title=${item.sjName}&paperId=${item.cContactid}&examType=2&type=${type}&courseId=${this.id}&IsPurchase=${this.isPurchase}`

      });
    },
    analysisClick(param) {
      // this.$router.push("");
      this.$router.push({
        path: "/partnerPage/analysis",
        query: {
          title: "错题记录",
          IsPurchase: true,
          examType: 168,
          wrongTopic: true,
          param: JSON.stringify(param),
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}

.center {
  align-items: center;
  text-align: center;
}

.between {
  justify-content: space-between;
}

.el-button--success::v-deep {
  background: #2586f5;
  border-color: #2586f5;
}

.el-button--default::v-deep {
  border-color: #2586f5;
  color: #2586f5;
}

.body-box {
  width: 100%;
  margin-top: 25px;

  .course-box {
    display: flex;
    flex-wrap: wrap;

    .course-pane {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #777777;
      border: 1px #777777 solid;
      min-width: 60px;
      margin: 5px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }

    .select {
      color: #2586f5;
      border: 1px #2586f5 solid;
    }
  }

  .expand-btn {
    font-size: 14px;
    font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
    font-weight: 600;
    color: #333333;
    line-height: 40px;
    min-width: 45px;
    color: #333333;
  }

  .expand {
    height: 40px;
    overflow: hidden;
  }

  .wrong-item {
    padding: 20px 5px;
    border-bottom: 1px solid #eeeeee;

    .wrong-left {
      .wrong-type {
        font-size: 16px;
        color: #333333;
        margin-left: 3px;

        .wrong-icon {
          width: 14px;
          height: 14px;
        }
      }

      .wrong-sub {
        font-size: 12px;
        color: #999999;
        margin: 15px 0px 0px 18px;

        span {
          color: #2586f5;
        }
      }
    }
  }
}
</style>
